<template>
  <item-page :item="item" v-if="item" type="information"></item-page>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["id"],
  inject: ["activePage"],
  data() {
    return {
      item: null,
    };
  },
  computed: {
    ...mapGetters(["getInformation"]),
  },
  methods: {
    ...mapActions(["loadInformation"]),
    async updateItem() {
      await this.loadInformation(this.id);
      const item = this.getInformation;
      console.log(item);
      if (!item) {
        console.log("no item found");
        this.$router.replace("/informations");
        return;
      }
      this.item = item;
    },
  },
  created() {
    this.activePage.value = this.$routeNames.routeInformationPage;
    this.updateItem();
  },
};
</script>

<style scoped></style>
